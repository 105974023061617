export var RELATED_POSTS_SETTINGS_PARAMS = {
    postCount: {
        wixParam: 'postCount',
        appSettingsPath: 'style.numbers.postCount',
        defaultValue: 3,
        minValue: 1,
        maxValue: 50,
    },
};
export var RELATED_POSTS_DISPLAY_PARAMS = {
    isSeeAllLinkEnabled: {
        wixParam: 'isSeeAllLinkEnabled',
        appSettingsPath: 'style.booleans.isSeeAllLinkEnabled',
        defaultValue: true,
    },
    isWidgetTitleEnabled: {
        wixParam: 'isWidgetTitleEnabled',
        appSettingsPath: 'style.booleans.isWidgetTitleEnabled',
        defaultValue: true,
    },
};
export var RELATED_POSTS_DISPLAY_PARAMS_MOBILE = {
    isSeeAllLinkEnabled: {
        wixParam: 'isSeeAllLinkEnabledMobile',
        appSettingsPath: 'style.booleans.isSeeAllLinkEnabledMobile',
        defaultValue: true,
    },
    isWidgetTitleEnabled: {
        wixParam: 'isWidgetTitleEnabledMobile',
        appSettingsPath: 'style.booleans.isWidgetTitleEnabledMobile',
        defaultValue: true,
    },
};
export var RELATED_POSTS_LAYOUT_PARAMS = {
    backgroundColor: {
        wixParam: 'backgroundColor',
        appSettingsPath: 'style.colors.backgroundColor',
        defaultValue: 'color-5',
        defaultOpacity: 0,
    },
    sliderLoop: {
        wixParam: 'sliderLoop',
        appSettingsPath: 'style.booleans.sliderLoop',
        defaultValue: false,
    },
    sliderAutoSlide: {
        wixParam: 'sliderAutoSlide',
        appSettingsPath: 'style.booleans.sliderAutoSlide',
        defaultValue: false,
    },
    sliderPauseTime: {
        wixParam: 'sliderPauseTime',
        appSettingsPath: 'style.numbers.sliderPauseTime',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'sliderShowArrows',
        appSettingsPath: 'style.booleans.sliderShowArrows',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'sliderArrowsPosition',
        appSettingsPath: 'style.numbers.sliderArrowsPosition',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'sliderArrowsSize',
        appSettingsPath: 'style.numbers.sliderArrowsSize',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'sliderArrowsColor',
        appSettingsPath: 'style.colors.sliderArrowsColor',
        defaultValue: 'color-5',
    },
};
export var RELATED_POSTS_LAYOUT_PARAMS_MOBILE = {
    backgroundColor: {
        wixParam: 'backgroundColorMobile',
        appSettingsPath: 'style.colors.backgroundColorMobile',
        defaultValue: 'color-5',
        defaultOpacity: 0,
    },
    sliderLoop: {
        wixParam: 'sliderLoopMobile',
        appSettingsPath: 'style.booleans.sliderLoopMobile',
        defaultValue: false,
    },
    sliderAutoSlide: {
        defaultValue: true,
        wixParam: 'sliderAutoSlideMobile',
        appSettingsPath: 'style.booleans.sliderAutoSlideMobile',
    },
    sliderPauseTime: {
        wixParam: 'sliderPauseTimeMobile',
        appSettingsPath: 'style.numbers.sliderPauseTimeMobile',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'sliderShowArrowsMobile',
        appSettingsPath: 'style.booleans.sliderShowArrowsMobile',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'sliderArrowsPositionMobile',
        appSettingsPath: 'style.numbers.sliderArrowsPositionMobile',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'sliderArrowsSizeMobile',
        appSettingsPath: 'style.numbers.sliderArrowsSizeMobile',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'sliderArrowsColorMobile',
        appSettingsPath: 'style.colors.sliderArrowsColorMobile',
        defaultValue: 'color-5',
    },
};
export var SEE_ALL_LINK_DESIGN_PARAMS = {
    layoutType: {
        wixParam: 'see-all-link-layoutType',
        appSettingsPath: 'style.numbers.see-all-link-layoutType',
        defaultValue: 0,
        values: {
            text: 0,
            button: 1,
        },
    },
};
export var SEE_ALL_LINK_TEXT_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'see-all-link-text-textFont',
        appSettingsPath: 'style.fonts.see-all-link-text-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'see-all-link-text-textColor',
        appSettingsPath: 'style.colors.see-all-link-text-textColor',
        defaultColor: 'color-8',
    },
    textColorHover: {
        wixParam: 'see-all-link-text-textColorHover',
        appSettingsPath: 'style.colors.see-all-link-text-textColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
    uppercase: {
        wixParam: 'see-all-link-text-uppercase',
        appSettingsPath: 'style.booleans.see-all-link-text-uppercase',
        defaultValue: false,
    },
};
export var SEE_ALL_LINK_BUTTON_DESIGN_PARAMS = {
    verticalPadding: {
        wixParam: 'see-all-link-button-verticalPadding',
        appSettingsPath: 'style.numbers.see-all-link-button-verticalPadding',
        defaultPadding: 6,
        minPadding: 0,
        maxPadding: 100,
    },
    horizontalPadding: {
        wixParam: 'see-all-link-button-horizontalPadding',
        appSettingsPath: 'style.numbers.see-all-link-button-horizontalPadding',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 100,
    },
    textFont: {
        wixParam: 'see-all-link-button-textFont',
        appSettingsPath: 'style.fonts.see-all-link-button-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'see-all-link-button-textColor',
        appSettingsPath: 'style.colors.see-all-link-button-textColor',
        defaultColor: 'color-1',
    },
    uppercase: {
        wixParam: 'see-all-link-button-uppercase',
        appSettingsPath: 'style.booleans.see-all-link-button-uppercase',
        defaultValue: false,
    },
    backgroundColor: {
        wixParam: 'see-all-link-button-backgroundColor',
        appSettingsPath: 'style.colors.see-all-link-button-backgroundColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    cornerRadius: {
        wixParam: 'see-all-link-button-cornerRadius',
        appSettingsPath: 'style.numbers.see-all-link-button-cornerRadius',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
    },
    borderColor: {
        wixParam: 'see-all-link-button-borderColor',
        appSettingsPath: 'style.colors.see-all-link-button-borderColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    borderWidth: {
        wixParam: 'see-all-link-button-borderWidth',
        appSettingsPath: 'style.numbers.see-all-link-button-borderWidth',
        defaultWidth: 0,
        minWidth: 0,
        maxWidth: 10,
    },
    textColorHover: {
        wixParam: 'see-all-link-button-textColorHover',
        appSettingsPath: 'style.colors.see-all-link-button-textColorHover',
        defaultColor: 'color-1',
        defaultOpacity: 1,
    },
    backgroundColorHover: {
        wixParam: 'see-all-link-button-backgroundColorHover',
        appSettingsPath: 'style.colors.see-all-link-button-backgroundColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
    borderColorHover: {
        wixParam: 'see-all-link-button-borderColorHover',
        appSettingsPath: 'style.colors.see-all-link-button-borderColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
};
export var WIDGET_TITLE_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'widget-title-textFont',
        appSettingsPath: 'style.fonts.widget-title-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'widget-title-textColor',
        appSettingsPath: 'style.colors.widget-title-textColor',
        defaultColor: 'color-8',
    },
};
