import {
  RELATED_POSTS_DISPLAY_PARAMS,
  IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH,
  RELATED_POSTS_DISPLAY_PARAMS_MOBILE,
} from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { AppState } from '../../common/types';

export const getUseMobileDisplaySettings = (state: AppState) =>
  getIsMobile(state) &&
  getIsMobileSettingEnabled(state, IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH);

export const getIsSeeAllLinkEnabled = (state: AppState) => {
  const param = getUseMobileDisplaySettings(state)
    ? RELATED_POSTS_DISPLAY_PARAMS_MOBILE
    : RELATED_POSTS_DISPLAY_PARAMS;

  return getAppSettingsValue({
    state,
    key: param.isSeeAllLinkEnabled.appSettingsPath,
    fallback: param.isSeeAllLinkEnabled.defaultValue,
  });
};

export const getIsWidgetTitleEnabled = (state: AppState) => {
  const param = getUseMobileDisplaySettings(state)
    ? RELATED_POSTS_DISPLAY_PARAMS_MOBILE
    : RELATED_POSTS_DISPLAY_PARAMS;

  return getAppSettingsValue({
    state,
    key: param.isWidgetTitleEnabled.appSettingsPath,
    fallback: param.isWidgetTitleEnabled.defaultValue,
  });
};
