import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { I18nextProvider } from '@wix/yoshi-flow-editor';
import { SECTION_RELATED_POSTS } from '@wix/communities-blog-client-common';
import AppLoaded from '../../../common/components/app-loaded';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { connect } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import {
  getIsCreatedWithResponsiveEditor,
  getLayoutMargins,
  getUseMobileDesignSettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';
import { initI18n } from '../../../common/services/init-i18n';
import { isInWix } from '../../../common/services/is-in-wix';
import {
  getLanguage,
  getIsMobile,
  isEditor,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import RelatedPosts from '../related-posts';
import styles from './app-root.scss';

class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (this.props.isEditor && this.props.createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }

  getI18nConfig = () => {
    const { language, translations } = this.props;

    if (!this.i18nConfig) {
      this.i18nConfig = initI18n(language, translations);
    }

    return this.i18nConfig;
  };

  render() {
    const { padding, useMobileDesign } = this.props;
    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener dataHook="related-posts-root">
        <I18nextProvider i18n={i18n}>
          <div
            style={{ padding }}
            className={classNames(
              styles.root,
              useMobileDesign ? styles.mobileBaseStyles : styles.baseStyles,
            )}
          >
            <RelatedPosts />
            <AppLoaded />
          </div>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.object,
  isEditor: PropTypes.bool,
  createdWithResponsiveEditor: PropTypes.bool,
  useMobileDesign: PropTypes.bool,
  padding: PropTypes.number,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isEditor: isEditor(state),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  useMobileDesign: getUseMobileDesignSettings(state),
  padding:
    (getIsMobile(state) && !getUseMobileLayoutSettings(state)) ||
    getIsCreatedWithResponsiveEditor(state)
      ? 0
      : getLayoutMargins({
          state,
          section: SECTION_RELATED_POSTS,
          layoutType: getLayoutType(
            state,
            SECTION_RELATED_POSTS,
            getUseMobileLayoutSettings(state),
          ),
        }),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
