import {
  RELATED_POSTS_SETTINGS_PARAMS,
  handleAggregatorResponseWithHeaders,
} from '@wix/communities-blog-client-common';
import setPosts from '../../common/actions/set-posts';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getRelatedPosts } from '../../common/selectors/post-selectors';
import { getDemoPosts } from '../../common/services/demo-posts';
import getEnvironment from '../../common/services/get-environment';
import { getTotalResults } from '../../common/services/pagination';
import { handleCategoriesResponse } from '../../common/store/categories/fetch-categories';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { fetchWidgetPosts } from './fetch-widget-posts';

export const RELATED_POSTS_ENTITY = 'related-posts';

export const fetchInitialData =
  () =>
  async (dispatch, getState, { aggregatorRequest, wixCodeApi, httpClient }) => {
    const state = getState();
    const instanceId = getInstanceId(state);

    const language = getQueryLocale(state);

    dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, true));
    const { translations, posts, categories } = await aggregatorRequest(
      `/v1/post-list-widget/render-model?postLimit=1&language=${language}`,
      { throwOnInvalidJson: true },
    );

    await dispatch(handleTranslationsResponse(translations));
    if (categories) {
      await dispatch(handleCategoriesResponse(categories));
    }
    await dispatch(fetchTopology(instanceId));
    await dispatch(handleAggregatorResponseWithHeaders(posts));

    await dispatch(fetchWidgetPosts());

    const relatedPosts = getRelatedPosts(getState());
    if (!relatedPosts.length && getEnvironment(wixCodeApi).isEditorSegment) {
      const demoPostsResponse = await getDemoPosts({
        httpClient,
        aggregatorRequest,
        getState,
        dispatch,
        wixCodeApi,
        query: {
          page: 1,
          pageSize: getAppSettingsValue({
            state,
            key: RELATED_POSTS_SETTINGS_PARAMS.postCount.appSettingsPath,
            fallback: RELATED_POSTS_SETTINGS_PARAMS.postCount.defaultValue,
          }),
          language: getQueryLocale(getState()),
        },
      });
      dispatch(setPosts(demoPostsResponse.body));
      dispatch(setPostCount(getTotalResults(demoPostsResponse.headers)));
    }
    dispatch(setIsLoading(RELATED_POSTS_ENTITY, undefined, false));
  };
